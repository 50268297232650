import React from 'react';
import { Button as MUIButton, Typography, Box } from '@mui/material';
import Link from '../Link';
import { connect } from 'react-redux';

interface ButtonProps {
  data: string; // entry id of button
  entries: any[];
}

const Button = (props: ButtonProps) => {
  const { entries, data } = props;

  const { style, link, title, roundedCorners } = entries.find(
    (entry) => entry.sys.id === data
  ).fields;

  const colors: string[] = style.split('-');


  const colorDefinitions = new Map<string, string>([
    ['white', '#FFFFFF'],
    ['yellow', '#FBEBAA'],
    ['blue', '#31394C'],
    ['violet', '#C02D7C'],
    ['transprent', 'transprent']
  ]);

  const textColor = colorDefinitions.get(colors[0]);
  const fillColor = colorDefinitions.get(colors[1]);
  const borderColor = colorDefinitions.get(colors[2]);

  let buttonColor:
    | 'success'
    | 'warning'
    | 'info'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'error' = 'success';
  if (fillColor === '#FBEBAA') buttonColor = 'warning';
  if (fillColor === '#31394C') buttonColor = 'info';

  const [buttonName, setButtonName] = React.useState(title);

  const content = (
    <MUIButton
      color={buttonColor}
      variant={fillColor === 'transprent' ? 'outlined' : 'contained'}
      sx={{
        marginRight: { md: '28px', xs: '13px' },
        height: { md: '58px', xs: '40px' },
        backgroundColor: fillColor,
        borderRadius: roundedCorners ? '29px' : '0',
        borderColor: borderColor,
        borderWidth: { md: '2px', xs: '1px' },
        p: '30px'
      }}
      className="black-text-wrapper"
    >
      <Typography
        component="p"
        sx={{
          fontSize: { md: '17px', xs: '14px' },
          color: textColor,
          fontFamily: 'Lato-Bold',
          textTransform: 'none',
        }}
      >
        {buttonName}
      </Typography>
    </MUIButton>
  );

  return (
    <>
      {link ? (
        <Link
          data={link.sys.id}
          setButtonName={(name: string) => setButtonName(name)}
        >
          <a style={{ textDecoration: 'none' }}>{content}</a>
        </Link>
      ) : (
        <>{content}</>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    entries: state.main.entries,
  };
};

export default connect(mapStateToProps, null)(Button);
